.App-logo {
  height: 13vmin;
  pointer-events: none;
  margin-bottom: 4rem;
}

.App-Home-Link {
  float: right; 
  margin-bottom: 4rem;
  height: 13vmin; 
  font-family: 'PTRootUI';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: rgba(6, 0, 69, 0.4);
  padding-top: 30px;
}

.App-header {
  min-height: 93vh;
  padding-top: 30px;
  background-image: url("./assets/images/background-img.png") !important;
  background-size: 550px !important;
  background-repeat: no-repeat !important;
  background-position: right bottom !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: 5rem;
  padding-right: 5rem;
  position: relative;
}

.App-link {
  color: #61dafb;
}

@media (max-width: 768px) {
  .App-header {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

footer {
  border-top: 1px solid rgba(6, 0, 69, 0.1);
  padding-top: 1rem;
  padding-bottom: 1rem;
  bottom: 0;
  color: #707070;
  height: 3.5em;
  left: 0;
  position: relative; 
  width:100%;
}

.footer-span {
  font-family: 'PTRootUI';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(6, 0, 69, 0.4);
}

.footer-alignRight {
  float: right;
  padding-right: 5rem;
}

.footer-alignLeft {
  float: left;
  padding-left: 5rem;
  padding-right: 5rem;
}

.ant-btn .ant-btn-text {
  font-family: 'PTRootUI' !important;
  color: red;
}